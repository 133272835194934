import React, { useRef, useEffect, useState } from "react";
import Layout from "../../components/shared/layout";
import styled from "styled-components";

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 24%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6%;
  margin-left: -12%;
  box-sizing: border-box;
`;

const Loader = styled.div`
  display: inline-block;
  flex: 1;
  width: 50%;
  aspect-ratio: 1;
  min-height: 75px;
  max-height: 100px;
  border: 10px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #333;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  align-self: center;
  box-sizing: border-box;

  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
`;

const Projects = () => {
  const projectMountRef = useRef(null);
  const [projectLoaded, setProjectLoaded] = useState(false);

  useEffect(() => {
    if (!projectMountRef.current) {
      console.log('no project mount ref available');
      return;
    }

    import('projectpalette/projectPalette')
      .then(project => {
        if (!project) {
          console.error('unable to load project');
          return; // should probably stop loading and display issue
        }

        setProjectLoaded(true);
        project.mount(projectMountRef.current)
      })
      .catch(err => console.error(err));
    
  }, [projectMountRef]);
  
  return (
    <Layout pageTitle="Projects">
      {
        !projectLoaded &&
        <LoaderContainer>
          <Loader />
          <span>Loading...</span>
        </LoaderContainer>
      }
      <div ref={projectMountRef} />
    </Layout>
  );
};

export default Projects;